<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Mission" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Title On Activity *"
                  v-model="titleOnActivity"
                  :error="!!errors.mission_title_activity"
                  :error-messages="errors.mission_title_activity"
                  @input="delete errors.mission_title_activity"
                />
                <div style="margin-bottom: 15px;">
                  <span>Description On Activity</span>
                  <ckeditor v-model="descriptionOnActivity" :editor="editor" :config="editorConfig"></ckeditor>
                  <div v-if="errors.mission_description_activity" style="color: red;">{{ errors.mission_description_activity[0] }}</div>
                </div>
                <div style="margin-bottom: 15px;">
                  <span>Content On Activity *</span>
                  <ckeditor v-model="contentOnActivity" :editor="editor" :config="editorConfig"></ckeditor>
                  <div v-if="errors.mission_content_activity" style="color: red;">{{ errors.mission_content_activity[0] }}</div>
                </div>
                <va-card class="mb-3" title="Image On Activity">
                  <div v-if="imageOnActivityExisting">
                    <a style="display: block;" target="_blank" :href="imageOnActivityExisting"><img style="max-width: 400px;" :src="imageOnActivityExisting"></a>
                    <div><va-button small color="danger" @click="imageOnActivityExisting = deleteImage(imageOnActivityId, 'mission_image_activity') ? '' : imageOnActivityExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    @input="delete errors.mission_image_activity"
                    dropzone
                    v-model="imageOnActivity"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('mission_image_activity', 'mission_image_activity', imageOnActivity[0])">Завантажити</va-button>
                  <div v-if="errors.mission_image_activity" style="color: red;">{{ errors.mission_image_activity[0] }}</div>
                </va-card>
                <va-input
                  label="Title On Career *"
                  v-model="titleOnCareer"
                  :error="!!errors.mission_title_career"
                  :error-messages="errors.mission_title_career"
                  @input="delete errors.mission_title_career"
                />
                <div style="margin-bottom: 15px;">
                  <span>Description On Career *</span>
                  <ckeditor v-model="descriptionOnCareer" :editor="editor" :config="editorConfig"></ckeditor>
                  <div v-if="errors.mission_description_career" style="color: red;">{{ errors.mission_description_career[0] }}</div>
                </div>
                <div style="margin-bottom: 15px;">
                  <span>Content On Career *</span>
                  <ckeditor v-model="contentOnCareer" :editor="editor" :config="editorConfig"></ckeditor>
                  <div v-if="errors.mission_content_career" style="color: red;">{{ errors.mission_content_career[0] }}</div>
                </div>
                <va-card class="mb-3" title="Image On Responsibility">
                  <div v-if="imageOnCareerExisting">
                    <a style="display: block;" target="_blank" :href="imageOnCareerExisting"><img style="max-width: 400px;" :src="imageOnCareerExisting"></a>
                    <div><va-button small color="danger" @click="imageOnCareerExisting = deleteImage(imageOnCareerId, 'mission_image_career') ? '' : imageOnCareerExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.mission_image_career"
                    v-model="imageOnCareer"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('mission_image_career', 'mission_image_career', imageOnCareer[0])">Завантажити</va-button>
                  <div v-if="errors.mission_image_career" style="color: red;">{{ errors.mission_image_career[0] }}</div>
                </va-card>
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
  },
  data () {
    return {
      titleOnActivity: '',
      descriptionOnActivity: '',
      contentOnActivity: '',
      imageOnActivity: [],
      imageOnActivityId: '',
      imageOnActivityExisting: '',
      titleOnCareer: '',
      descriptionOnCareer: '',
      contentOnCareer: '',
      imageOnCareer: [],
      imageOnCareerId: '',
      imageOnCareerExisting: '',

      errors: [],

      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_AGRO_API_URL}/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        mission_title_activity: this.titleOnActivity,
        mission_description_activity: this.descriptionOnActivity,
        mission_content_activity: this.contentOnActivity,

        mission_title_career: this.titleOnCareer,
        mission_description_career: this.descriptionOnCareer,
        mission_content_career: this.contentOnCareer,
      }
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    uploadImage (param, key, file, agg = 'mission') {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append(param, file)
      formData.append('lang', this.locale)
      formData.append('aggregate', agg)
      formData.append('key', key)
      formData.append('multiple', '0')
      formData.append('file_id', '0')
      axios.post(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/upload/11?lang=${this.locale}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id, fieldKey) {
      return axios.post(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/11/delete-file/${id}?lang=${this.locale}`, {
        key: fieldKey,
      })
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      axios.put(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/11?lang=${this.locale}`, this.putData)
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    validate () {
      axios.put(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/validate/11?lang=${this.locale}`, this.putData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => {
          this.errors = response.data
          if (!Object.keys(this.errors).length) {
            this.submit()
          }
        })
        .catch(error => {
          console.log(error)
          this.showToast('Validate Fetch Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/11?lang=${this.locale}`)
        .then(response => {
          this.titleOnActivity = response.data.mission_title_activity
          this.descriptionOnActivity = response.data.mission_description_activity ? response.data.mission_description_activity : ''
          this.contentOnActivity = response.data.mission_content_activity ? response.data.mission_content_activity : ''
          this.imageOnActivityExisting = response.data.mission_image_activity ? process.env.VUE_APP_AGRO_ADMIN + response.data.mission_image_activity : null
          this.imageOnActivityId = response.data.mission_image_activity_id

          this.titleOnCareer = response.data.mission_title_career
          this.descriptionOnCareer = response.data.mission_description_career ? response.data.mission_description_career : ''
          this.contentOnCareer = response.data.mission_content_career ? response.data.mission_content_career : ''
          this.imageOnCareerExisting = response.data.mission_image_career ? process.env.VUE_APP_AGRO_ADMIN + response.data.mission_image_career : null
          this.imageOnCareerId = response.data.mission_image_career_id
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
